<template>
   <div class="grid crud-demo">
        <Toast />
        <Loader v-model="loading" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <Panel header="Facturas">
                    <BasicFormToolbar  @refresh="refresh" @list="list" :actions="[ 'list', 'refresh']" />
                    <Fieldset :toggleable="true" legend="Parametros">
                        <div class="p-fluid p-formgrid grid">
                            <FormCalendar :wrapperClass="'field col-4'" :label="'Fecha Inicial'" v-model="entity.initial_date"></FormCalendar>
                            <FormCalendar :wrapperClass="'field col-4'" :label="'Fecha Final'" v-model="entity.final_date"></FormCalendar>
                        </div>
                    </Fieldset>
                    <br />
                    <div class="grid">
                        <div class="col-12 scrollable-table" style="overflow-x: scroll;">
                            <Fieldset :toggleable="true" legend="Resultados">
                                <BasicDatatable 
                                :rowaction="true"
                                :files="['xml','pdf']" 
                                @xml="downloadXML"
                                @pdf="downloadPDF"
                                :exportFilename="'FACTURAS_BW'"
                                :headers="headers" 
                                :rows="entities" />
                            </Fieldset>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
import BasicDatatable from '../../../../components/general/BasicDatatable.vue'
import BasicFormToolbar from '../../../../components/general/BasicFormToolbar.vue'
import FormCalendar from '../../../../components/general/FormCalendar.vue'
import Loader from '../../../../components/general/Loader.vue'
import formMixin from '../../../../mixins/sessionMixin'
import { ErrorToast, HeaderGrid } from '../../../../utilities/General'

import axios from 'axios';
require('../../../../utilities/DATE_UTILITIES.js')

export default {
    mixins: [formMixin],
    components: {
        BasicDatatable, BasicFormToolbar, FormCalendar, Loader
    },
    data() {
        return {
            loading: false,
            entity: {
                initial_date: new Date().getFirstDayOfMonth(),
                final_date: new Date().getLastDayOfMonth()
            },
            entities: [],
            headers: [
                new HeaderGrid('ID','id'),
                new HeaderGrid('Factura', 'concat_invoice', {formula: 'concat', expression: 'serie + {-} + invoice_id'}),
                new HeaderGrid('Fecha','date', { type: 'date' }),
                new HeaderGrid('Cliente','receiver_name', { filter_options: true }),
                new HeaderGrid('RFC','receiver_rfc', { filter_options: true }),
                new HeaderGrid('Subtotal','subtotal', { type: 'currency', function: 'SUM' }),
                new HeaderGrid('IVA','iva', { type: 'currency', function: 'SUM' }),
                new HeaderGrid('Total','total', { type: 'currency', function: 'SUM' }),
                new HeaderGrid('Saldo Pendiente','total', { type: 'currency', function: 'SUM',  formula: 'evaluate', expression: 'total - amount_settled' }),
                new HeaderGrid('Estatus','status'),
            ]
        }
    },
    methods: {
        async downloadXML(payload) {
            this.loading = true;
            try {
                let response = await axios({
                    method: "post",
                    url: 'BusinessWallet/CyberSource/Admin/Invoice/xml',
                    data: {
                        xml: payload.cfd_xml
                    },
                    responseType: "blob"
                });
                var content = response.data;
                var blob = new Blob([content]);
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download =  payload.file_name + ".xml";
                link.click();
                URL.revokeObjectURL(link.href);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async downloadPDF(payload) {
            this.loading = true;
            try {
                let response = await axios({
                    method: "post",
                    url: 'BusinessWallet/CyberSource/Admin/Invoice/pdf',
                    data: {
                        file_name: payload.file_name
                    },
                    responseType: "blob"
                });
                var content = response.data;
                var blob = new Blob([content]);
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download =  payload.file_name + ".pdf";
                link.click();
                URL.revokeObjectURL(link.href);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async list() {
            this.loading = true;
            try {
                this.entities = (await axios.post('BusinessWallet/CyberSource/Admin/Invoice/external', {
                    initial_date: this.entity.initial_date,
                    final_date: this.entity.final_date
                })).data;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        }
    },
    async mounted() {
        this.list();
    }
}
</script>

<style>

</style>